import React from "react";

function AdobeTargetServices() {
	return (
		<section className="section section--services section--wave-bg --section-normal-tb-padding">
			<div className="container">
				<div className="container__row --flex-center --section-small-tb-padding">
					<div className="container__col-md-5 container__col-xs-12 --section-large-tb-padding">
						<h2 className="container__heading">Our Adobe Target Services</h2>
						<div className="container__row">
							<div className="container__col-md-10">
								<div className="container__services">
									<div className="container__row">
										<div className="container__col-md-2 container__col-xs-2">
											<img src="../../solutions/checkmark.png" alt="Hashout" />
										</div>
										<div className="container__col-md-10 container__col-xs-10">
											<h5 className="container__sub-heading">
												Discover Personalisation Use-Cases
											</h5>
										</div>
									</div>
									<div className="container__row">
										<div className="container__col-md-2  container__col-xs-2">
											<img src="../../solutions/checkmark.png" alt="Hashout" />
										</div>
										<div className="container__col-md-10  container__col-xs-10">
											<h5 className="container__sub-heading">
												Strategy services to access of Adobe Target usage and
												recommend/optimize{" "}
											</h5>
										</div>
									</div>
									<div className="container__row">
										<div className="container__col-md-2  container__col-xs-2">
											<img src="../../solutions/checkmark.png" alt="Hashout" />
										</div>
										<div className="container__col-md-10  container__col-xs-10">
											<h5 className="container__sub-heading">
												Simple integrations with Data and Web
											</h5>
										</div>
									</div>
									<div className="container__row">
										<div className="container__col-md-2  container__col-xs-2">
											<img src="../../solutions/checkmark.png" alt="Hashout" />
										</div>
										<div className="container__col-md-10  container__col-xs-10">
											<h5 className="container__sub-heading">
												New Setup (Strategy, Integrations and Implementation)
											</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container__col-md-6 container__col-xs-12 container__col-md-offset-1 --flex-center">
						<img
							className="container__img --bg-absolute-right"
							src="../../solutions/platforms/adobe-target-services.png"
							alt="Adobe Target Services"
						/>
					</div>
				</div>
			</div>
			{/* <img className="section__wave" src="../../solutions/wave.svg" alt="Hashout" /> */}
		</section>
	);
}

export default AdobeTargetServices;
