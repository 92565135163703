import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import AdobeTargetBanner from "../components/Platforms/AdobeTargetBanner/AdobeTargetBanner";
import AdobeTargetVideo from "../components/Platforms/AdobeTargetVideo/AdobeTargetVideo";
import AdobeTargetBenefits from "../components/Platforms/AdobeTargetBenefits/AdobeTargetBenefits";
import AdobeTargetServices from "../components/Platforms/AdobeTargetServices/AdobeTargetServices";
import { Helmet } from "react-helmet";

function AdobeAnalytics() {
	const scrollTop = () => {
		window.scroll(0, 1);
	};
	return (
		<Layout><Helmet>
		<meta charSet="utf-8" />
		   <title>
	   Hashout Technologies - Adobe Target
		   </title>
		   <meta
			 name="description"
			 content="A/B Testing and Omnichannel Personalisation at Scale to delight every customer, every time."
		   ></meta></Helmet>
   
			<SolutionsHelmet />
			<AdobeTargetBanner />
			<AdobeTargetServices />
			<AdobeTargetVideo />
			<AdobeTargetBenefits />
			<LearnAboutUs page="adobe-target"/>
			<a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
				<img
					className="go-to-top__img"
					src="../../solutions/go-to-top.png"
					alt="Go to top"
				/>
			</a>
		</Layout>
	);
}
export default AdobeAnalytics;
