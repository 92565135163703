import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function AdobeTargetBanner() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--banner">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h1 className="container__heading container__heading--platform">
              Adobe Target{" "}
            </h1>
            <img
              className="container__logo"
              src="../../solutions/platforms/solution-partner.png"
              alt="Adobe Experience Manager"
            />
            <h2 className="container__sub-heading container__sub-heading--platform">
              A/B Testing and Omnichannel Personalisation at Scale to delight
              every customer, every time.{" "}
            </h2>
            <h6 className="container__content">
              Hashout is an Adobe Solution Partner and an expert in Adobe
              products focussed on Personalisation to help craft clear use-cases
              for Personalization and deliver them to your customers to derive
              higher customer satisfaction and delight.
            </h6>
            <a className="container__link" href="#" onClick={openModal}>
              Create simple Personalisations for your customers using Adobe
              Target
            </a>
            <button
              className="button container__button container__button--platform button--blue"
              onClick={openModal}
            >
              Get 40 hrs free consultation!
            </button>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/platforms/adobe-target.png"
              alt="Adobe Target"
            />
          </div>
        </div>
      </div>
      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="Adobe-Target-Banner"
        page="Adobe-Target"
       
      />
    </section>
  );
}

export default AdobeTargetBanner;
