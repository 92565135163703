import React from "react";

function AdobeTargetBenefits() {
	return (
		<section className="section section--tools section--adobe-benefits section--bg-light-blue section--ldots-reverse-bg --section-medium-tb-padding">
			<div className="container">
				<div className="container__row">
					<div className="container__col-xs-12">
						<h2 className="container__heading">Adobe Target benefits</h2>
					</div>
				</div>
				<div className="container__row --flex-center">
					<div className="container__col-md-4 container__col-xs-12">
						<div className="card">
							<img
								className="card__image"
								src="../../solutions/platforms/benefits/omnichannel-personalization.png"
								alt="Omnichannel personalization"
							/>
							<a href="javascript:void(0);">
								<h5 className="card__title">Omnichannel personalization</h5>
							</a>
							<p className="card__description">
								Deliver personalized experience consistently across the entire
								customer journey even if it moves from one channel to another.{" "}
							</p>
						</div>
					</div>
					<div className="container__col-md-4 container__col-xs-12">
						<div className="card">
							<img
								className="card__image"
								src="../../solutions/platforms/benefits/multivariate-testing.png"
								alt="A/B and Multivariate Testing"
							/>
							<a href="javascript:void(0);">
								<h5 className="card__title">A/B and Multivariate Testing</h5>
							</a>
							<p className="card__description">
								Ability to create A/B and multivariate tests to learn the most
								effective combination of content, layouts, UX, and more through
								your websites and other digital properties.
							</p>
						</div>
					</div>
					<div className="container__col-md-4 container__col-xs-12">
						<div className="card">
							<img
								className="card__image"
								src="../../solutions/platforms/benefits/ai-powered.png"
								alt="AI-powered Automation and Scale"
							/>
							<a href="javascript:void(0);">
								<h5 className="card__title">AI-powered Automation and Scale</h5>
							</a>
							<p className="card__description">
								AI and machine learning, powered by Adobe Sensei, lets you test
								and personalize at scale which breaks the limitation of
								rule-driven or manual approaches.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default AdobeTargetBenefits;
